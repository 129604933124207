import React from 'react';
import { marked } from 'marked';
import { Link, useParams} from 'react-router-dom';
import DOMPurify from 'dompurify';
import styles from './Post.module.css'


interface PostProps {
    id: string;
    title: string;
    content: string;
    date: string;
    summaryView?: boolean;
    posts?: PostProps[];
}

const Post: React.FC<PostProps> = ({ id, title, date, posts, summaryView = false }) => {
    
    let { postId } = useParams();

    if (summaryView) {
        return (
            <div className={ styles["post-summary"]}>
                <Link to={`/post/${id}`}>
                    <p>{date}</p>
                    <h2>{title}</h2>
                    <br></br>
                </Link>
            </div>
        );
    } else {
            
        let post = posts?.find(p => p.id === postId); 
        if (!post) {
            return <div>Post not found</div>
        }
        const sanitizedHTML = DOMPurify.sanitize(marked(post.content));
        return (
            <div className={styles.container}>
                <h2 className={styles["post-title"]}>{post.title}</h2>
                <p className={styles["post-date"]}>{post.date}</p>
                <div className={styles["post-content"]} dangerouslySetInnerHTML={{__html: sanitizedHTML}}></div>
            </div>
        );
    }
};

export default Post;