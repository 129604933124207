import React, { useState, useEffect } from 'react';
import {Routes, Route} from 'react-router-dom';
import Header from './components/Header';
import Post from './components/Post';
import PostList from './components/PostList';
import './App.css';
import './theme.css';
import postsData from './markdown_files/posts.json'

function App() {
  const [theme, setTheme] = useState('light');

  const toggleTheme = () => {
    setTheme((prevTheme: string) => prevTheme === 'light' ? 'dark' : 'light');
  }

  useEffect(() => { 
      document.body.className = theme + '-mode';
    }, [theme]);

  return (
    <div>
      <Header title="defifaqs" theme={theme} onToggleTheme={toggleTheme} />
      <Routes>
        <Route path='/' element={<PostList posts={postsData} />}></Route>
        <Route path='/post/:postId' element={<Post id='' title='' content='' date='' posts={postsData} summaryView={false} />}></Route>
      </Routes>
    </div>      
  );
}

export default App;
