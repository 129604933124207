import React from 'react';
import Post from '../Post';
import styles from './PostList.module.css';

interface PostProps {
    id: string;
    title: string;
    content: string;
    date: string;
    summaryView?: boolean;
}

interface PostListProps {
    posts: PostProps[]
}

const PostList: React.FC<PostListProps> = ({posts}) => {
    return (
        <div className={styles["post-list"]}>
            {
                posts.map((post) => (
                    <Post key={post.id} {...post} summaryView = {true} />
                ))
            }
        </div>      
    );
};

export default PostList;