import React from 'react';
import styles from './Header.module.css';
import '../../theme.css';

interface HeaderProps {
    title: string;
    theme: string;
    onToggleTheme: () => void;
}

const Header: React.FC<HeaderProps> = ({title, theme, onToggleTheme}) => {
    return (
        <header className={styles.header}>
            <div></div>
            <h2 className={styles.title}>{title}</h2>
            <button className={`${styles.button} ${theme}-mode`} onClick={onToggleTheme} >{theme}</button>
        </header>
    );
};

export default Header;  